<template>
  <a-modal
    :visible="visible"
    :wrap-class-name="$style.modalSync"
    :closable="false"
  >
    <img
      v-if="detailData?.logo"
      :src="require(`@/assets/store/add-store/${detailData?.logo}`)"
    />
    <h4 class="mt-5">
      {{ $t('add_store.sync_tips') }}
    </h4>
    <p class="mt-2 text-center">
      <template v-if="detailData?.channelCode === 'tiktok_tokopedia'">
        {{ $t('add_store.note_tips_tiktok_tokopedia', { name: detailData?.name }) }}
      </template>
      <template v-else>
        {{ $t('add_store.note_tips', { name: detailData?.name }) }}
      </template>
    </p>
    <template #footer>
      <a-button
        class="mr-2"
        @click="typeof back !== 'undefined' && back(), openModal(null)"
      >
        {{ $t('add_store.back') }}
      </a-button>
      <a-button
        type="primary"
        :loading="integration.loadingSync"
        @click="syncMarketPlace(detailData?.id, detailData?.name, detailData?.channelCode)"
      >
        {{ $t('add_store.start_sync') }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import data from '@/services/store/data'

export default {
  name: 'ModalSync',
  props: {
    visible: Boolean,
    back: Function,
    openModal: Function,
    openModalSuccess: Function,
    idData: Number,
  },
  computed: {
    detailData() {
      return data.find((val) => val.id === this.idData)
    },
    integration() {
      return this.$store.state.integration
    },
  },
  methods: {
    async syncMarketPlace(id, name, channelCode) {
      if (id !== null) {
        let route = {
          path: this.$route.path,
          query: this.$route.query,
        }
        /* Sync tiktok */
        if (name === 'Tiktok') {
          route = {
            ...route,
            query: {
              ...route.query,
              'company-aggregator-id': id,
              name: name.toLowerCase(),
            },
          }
          localStorage.setItem('pathCallback', JSON.stringify(route))
          const { data } = await this.$store.dispatch('integration/SYNCTIKTOK', id)
          window.location = data.data.authorized_url
        }
        if (channelCode === 'tiktok_tokopedia') {
          route = {
            ...route,
            query: {
              ...route.query,
              'company-aggregator-id': id,
              name: name.toLowerCase(),
            },
          }
          localStorage.setItem('pathCallback', JSON.stringify(route))
          localStorage.setItem('channelId', null)
          localStorage.setItem('needBinding', true)
          const { data } = await this.$store.dispatch('integration/SYNCTIKTOK', id)
          window.location = data.data.authorized_url
        }
        if (channelCode === 'shopee_id') {
          const salesChannel = {
            id: id,
            code: this.detailData?.channelCode,
          }
          let url = new URL(window.location.origin);
          this.$store.dispatch('integration/SYNCSHOPEE', {
            sales_channel: salesChannel,
            redirect_url: `${url}integration/shopee`,
          })
            .then(({ data }) => {
              if (data?.data?.authorization_url && data?.data?.client_id) {
                route = {
                  ...route,
                  query: {
                    ...route.query,
                    'company-aggregator-id': id,
                    name: name.toLowerCase(),
                    sales_channel: salesChannel,
                    client_id: data?.data?.client_id,
                  },
                }
                localStorage.setItem('pathCallback', JSON.stringify(route))
                window.location = data?.data?.authorization_url
              } else {
                this.$notification.error({
                  message: 'Redirect Failed',
                })
              }
            })
            .catch((err) => {
              this.$notification.error({
                message: 'Connected Failed',
                description: err.response?.data?.message || err.message,
              })
            })

        } else if (channelCode === 'lazada_id') {
          const salesChannel = {
            id: id,
            code: this.detailData?.channelCode,
          }
          let url = new URL(window.location.origin);
          this.$store.dispatch('integration/SYNCLAZADA', {
            redirect_url: `${url}integration/lazada/callback`,
          })
            .then(({ data }) => {
              if (data?.data?.authorization_url && data?.data?.client_id) {
                route = {
                  ...route,
                  query: {
                    ...route.query,
                    'company-aggregator-id': id,
                    name: name.toLowerCase(),
                    sales_channel: salesChannel,
                    client_id: data?.data?.client_id,
                  },
                }
                localStorage.setItem('pathCallback', JSON.stringify(route))
                window.location = data?.data?.authorization_url
              } else {
                this.$notification.error({
                  message: 'Redirect Failed',
                })
              }
            })
            .catch((err) => {
              this.$notification.error({
                message: 'Connected Failed',
                description: err.response?.data?.message || err.message,
              })
            })
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
