import apiClient from '@/services/axios'

export const getWarehouseTiktok = ({ channel_id, business_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/tiktok/integration/query/warehouse/map/list/${channel_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const checkTiktokSyncStockStatus = ({ business_id, channel_code, channel_id }) => {
  return apiClient({
    method: 'get',
    url: `channel/${channel_code}/product/query/${channel_id}/sync/latest-process`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getTiktokWarehouseChannelList = ({ channel_id, business_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/tiktok/integration/${channel_id}/warehouse`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const adjusTiktoktMappingWarehouseChannel = ({ channel_id, business_id, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/tiktok/integration/${channel_id}/warehouse`,
    data,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const bindingTokopedia = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: 'channel/tiktok/integration/bind/tokopedia',
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const disconnectTiktokIntegration = ({ channel_id, business_id }) => {
  return apiClient({
    method: 'put',
    url: 'channel/tiktok/integration/cmd/disconnect',
    params: {
      channel_id,
    },
    headers: {
      'Business-Id': business_id,
    },
  })
}