export default [
  /* Store */
  {
    id: 433,
    name: 'Shopee',
    type: 'store',
    logo: 'shopee.svg',
    status: '',
    channelCode: 'shopee_id',
    redirectIntegrate: true,
  },
  {
    id: 2,
    name: 'Tokopedia',
    type: 'store',
    logo: 'tokopedia.svg',
    status: '',
    channelCode: 'tokopedia_id',
    redirectIntegrate: false,
  },
  {
    id: 5,
    name: 'Lazada',
    type: 'store',
    logo: 'lazada.svg',
    status: '',
    channelCode: 'lazada_id',
    redirectIntegrate: true,
  },
  {
    id: 123,
    name: 'Tiktok',
    type: 'store',
    logo: 'tiktok.svg',
    status: '',
    channelCode: 'tiktok_id',
    redirectIntegrate: true,
  },
  {
    id: 6,
    name: 'Blibli',
    type: 'store',
    logo: 'blibli.svg',
    status: '',
    channelCode: 'blibli_id',
    redirectIntegrate: false,
  },
  {
    id: 93,
    name: 'Tiktok Tokopedia',
    type: 'store',
    logo: 'tiktok-shop-tokopedia.webp',
    status: '',
    channelCode: 'tiktok_tokopedia',
    redirectIntegrate: true,
  },
  // {
  //   id: 6,
  //   name: 'Elevenia',
  //   type: 'store',
  //   logo: 'elevenia.svg',
  //   status: 'coming soon',
  // },
  // {
  //   id: 7,
  //   name: 'JD.id',
  //   type: 'store',
  //   logo: 'jd_id.svg',
  //   status: 'coming soon',
  // },
  // {
  //   id: 8,
  //   name: 'Zilingo',
  //   type: 'store',
  //   logo: 'zilingo.svg',
  //   status: 'coming soon',
  // },
  // {
  //   id: 9,
  //   name: 'Zalora',
  //   type: 'store',
  //   logo: 'zalora.svg',
  //   status: 'coming soon',
  // },
  // {
  //   id: 10,
  //   name: 'Gofood',
  //   type: 'store',
  //   logo: 'go_food.svg',
  //   status: 'coming soon',
  // },
  // {
  //   id: 11,
  //   name: 'GrabFood',
  //   type: 'store',
  //   logo: 'grab_food.svg',
  //   status: 'coming soon',
  // },
  // /* Warehouse */
  // {
  //   id: 12,
  //   name: 'Lazada',
  //   type: 'warehouse',
  //   logo: 'lazada.svg',
  //   status: '',
  // },
  // {
  //   id: 13,
  //   name: 'JD.id',
  //   type: 'warehouse',
  //   logo: 'jd_id.svg',
  //   status: '',
  // },
  // {
  //   id: 14,
  //   name: 'Javelin',
  //   type: 'warehouse',
  //   logo: 'javelin.svg',
  //   status: '',
  // },
]
