<template>
  <a-card
    class="cardData"
    :class="{
      [$style.cardItem]: true,
      [$style.cardItemActive]: active === parseInt(dataCard.id),
    }"
    @click="onClickCard"
  >
    <div :class="$style.content">
      <img :src="require(`@/assets/store/add-store/${dataCard.logo}`)" />
    </div>
    <div :class="$style.footer">
      <span>{{ dataCard.name }}</span>
      <span
        v-if="dataCard.status === 'coming soon'"
        :class="$style.coming_soon"
      >{{ $t('add_store.coming_soon') }}</span>
    </div>
  </a-card>
</template>

<script>
// import addStore from '@/composable/store/addStore'

export default {
  name: 'CardItem',
  props: {
    dataCard: Object,
    active: Number,
    setActive: Function,
    openModal: Function,
  },
  setup() {},
  watch: {
    '$route.query.default_channel_code': {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          const splitValue = value.split(',')
          if (splitValue.includes(this.dataCard.channelCode)) {
            this.onClickCard()
          }
        }
      },
    },
  },
  methods: {
    onClickCard() {
      if (this.$route.query.business_id) {
        this.dataCard.status === '' &&
          typeof this.openModal !== 'undefined' &&
          this.openModal(this.dataCard.id)
        this.dataCard.status === '' &&
          typeof this.setActive !== 'undefined' &&
          this.setActive(this.dataCard.id)
      } else {
        this.$notification.error({
          message: 'Gagal',
          description: 'harap pilih bisnis terlebih dahulu, sebelum melakukan integrasi toko',
        })
      }
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
